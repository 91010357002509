import React from 'react';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockBuilder from '../../BlockBuilder';

import SectionContainer from '../SectionContainer';

import css from './SectionPartD.module.css';

import Community1 from './img/World-LikeHome-cest.jpg';
import Community2 from './img/Gratitude_to.jpg';
import Community3 from './img/Ne_Jamais.jpg';
import { Button } from '../../../../components';
import PLAY from './img/play.png';
import { FaTimes } from 'react-icons/fa';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import TeaserST_WIH_VF from './videos/TeaserST_WIH_VF.mp4';


// Section component that shows features.
// Block content are shown in a row-like way:
// [image] text
// text [image]
// [image] text
const SectionPartD = props => {


  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const onWIHCommunity = (value) => {
    window.location.replace(value);
  }

  return (
    <>
      <div className={css.communitySection}>
        <br></br>
        <h6 className={css.hPrimary}><FormattedMessage id="SectionPartD.headingPrimary" /></h6>
        <h4 className={css.landingSubTItle}><FormattedMessage id="SectionPartD.subTitle" /></h4>
        <br></br>
        <div className={css.communityGrid}>
          <div className={css.communityCard}>
            <a href='https://world-like-home.community/t/world-like-home-c-est-etre-soucieux-du-bien-etre-de-lautre/728' target='_blank'>
              <img src={Community1} />
            </a>
            <div className={css.communitycontents}>
              <div className={css.dateDisplay}><FormattedMessage id="SectionPartD.dateDisplay1" /></div>
              <p>
                <FormattedMessage id="SectionPartD.cardText1" />
              </p>
              <p>
                <FormattedMessage id="SectionPartD.cardTextReadMore" />
                <span className={css.readMoreArrow}><a href='https://world-like-home.community/t/world-like-home-c-est-etre-soucieux-du-bien-etre-de-lautre/728' target='_blank' className={css.anchor}> ➜</a></span>
              </p>
            </div>
          </div>
          <div className={css.communityCard}>
            <a href='https://world-like-home.community/t/a-39-ans-peut-on-avoir-un-visa-etudiant-pour-la-france-decouvrons-la-reponse/5680' target='_blank'>
              <img src={Community2} />
            </a>
            <div className={css.communitycontents}>
              <div className={css.dateDisplay}><FormattedMessage id="SectionPartD.dateDisplay2" /></div>
              <p><FormattedMessage id="SectionPartD.cardText2" />
              </p>
              <p>
                <FormattedMessage id="SectionPartD.cardTextReadMore" />
                <span className={css.readMoreArrow}><a href='https://world-like-home.community/t/a-39-ans-peut-on-avoir-un-visa-etudiant-pour-la-france-decouvrons-la-reponse/5680' target='_blank' className={css.anchor}> ➜</a></span>
              </p>
            </div>
          </div>
          <div className={css.communityCard}>
            <a href='https://world-like-home.community/t/ne-jamais-avoir-peur-daffronter-les-difficultes/2748' target='_blank' >
              <img src={Community3} />
            </a>           
            <div className={css.communitycontents}>
              <div className={css.dateDisplay}><FormattedMessage id="SectionPartD.dateDisplay3" /></div>
              <p><FormattedMessage id="SectionPartD.cardText3" />  </p>
              <p>
                <FormattedMessage id="SectionPartD.cardTextReadMore" /> 
                <span className={css.readMoreArrow}><a href='https://world-like-home.community/t/ne-jamais-avoir-peur-daffronter-les-difficultes/2748' target='_blank' className={css.anchor}> ➜</a></span>
              </p>
            </div>
          </div>
        </div>
        <Button className={css.buttonSearchAll} onClick={() => { onWIHCommunity('https://world-like-home.community/') }}><FormattedMessage id="SectionPartD.wihCommunity" /></Button>
      </div>

      <div className={css.esaExp}>
        <img src={PLAY} width={70} onClick={toggleModal} />
      </div>
      {isOpen && (
        <div className={css.modaloverlay}>
          <div className={css.modal}>
            <div className={css.modalheader}>
              <button className={css.closebtn} onClick={toggleModal}>
                <FaTimes />
              </button>
            </div>
            <div className={css.modalcontent}>
              <iframe class="mfp-iframe" src={TeaserST_WIH_VF} allowfullscreen="yes" allow="autoplay" height={500} width={800} frameBorder={0}></iframe>
            </div>
          </div>
        </div>


      )}
      <a href='https://www.youtube.com/watch?v=iFoy1LFFlCE' target='_blank' className={css.anchor}>
        <Button className={css.buttonSearchAllKeepWatching}><FormattedMessage id="SectionPartD.keepWatching" /></Button>
      </a>



    </>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionPartD.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  isInsideContainer: false,
  options: null,
};

SectionPartD.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionPartD;
