import React from 'react';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockBuilder from '../../BlockBuilder';

import SectionContainer from '../SectionContainer';

import css from './SectionPartE.module.css';
import HAPPY from './img/efuet.jpg';
import { Button } from '../../../../components';
import PLOGO1 from './img/AXA-Emblem.png';
import PLOGO2 from './img/le_mans_innovation.png';
import PLOGO3 from './img/microsofy-fhub.png';
import { FormattedMessage } from 'react-intl';

// Section component that shows features.
// Block content are shown in a row-like way:
// [image] text
// text [image]
// [image] text
const SectionPartE = props => {
  const onOurStory = (value) => {
    window.location.replace(value);
  }

  return (
    <>
      <div className={css.weBeliveSection}>
        <div className={css.contentSec}>
          <h3><b className={css.customBold}> <FormattedMessage id="SectionPartE.heading" /></b></h3>
          <p className={css.textCenter}>
            <FormattedMessage id="SectionPartE.description"
              values={{
                b: msgFragment => <b>{msgFragment}</b>,
                br:<br/>
              }}
            />
          </p>
        </div>
        <img src={HAPPY} className={css.happyImage} />
      </div>
      <Button className={css.buttonSearchAll} onClick={() => { onOurStory('p/about') }}><FormattedMessage id="SectionPartE.ourStory" /></Button>
      <br></br>
      <div className={css.patnersSection}>
        <div>
          <h3 className={css.patnersLogoHeading}> <FormattedMessage id="SectionPartE.ourPartners"
            values={{
              b: msgFragment => <b>{msgFragment}</b>,
            }}
          /></h3>
        </div>
        <br></br>
        <div className={css.patnersLogo}>
          <img src={PLOGO1} height={100} />
          <img src={PLOGO2} height={100} />
          <img src={PLOGO3} height={100} />
        </div>
      </div>
    </>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionPartE.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  isInsideContainer: false,
  options: null,
};

SectionPartE.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionPartE;
