import React from 'react';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockBuilder from '../../BlockBuilder';

import SectionContainer from '../SectionContainer';
import css from './SectionPartB.module.css';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// Section component that shows features.
// Block content are shown in a row-like way:
// [image] text
// text [image]
// [image] text
const SectionPartB = props => {

  const history = useHistory();
  const onClickImage = (value) => {
    history.push('s?pub_listingType=' + '' + value);
  }

  return ( 
    <>
      <div className={css.blockService}>
        <br></br>
        <h4 className={css.landingSubTItle}>
          <FormattedMessage id="SectionPartB.subTitle"
          values={{
            b: msgFragment => <b>{msgFragment}</b>,
          }}
        /></h4>
        <div className={css.servicesSection}>
          <div className={css.hostFamily}  onClick={()=>{onClickImage('Host_Family')}}>
          <h5 className={css.headingScaled} ><FormattedMessage id="SectionPartB.firstCardText" /></h5>
            <div className={css.hostFamilyContent}>
           
            </div>
          </div>
          <div className={css.admission} onClick={()=>{onClickImage('Help_to_get_an_Accommodation')}}>
          <h5  className={css.headingScaled} ><FormattedMessage id="SectionPartB.secondCardText" /></h5>
            <div className={css.admissionContent}>
            
            </div>
          </div>

        </div>


        <div className={css.servicesSectionsecond}>
          <div className={css.handEsa} onClick={()=>{onClickImage('ESA')}}>
          <h5 className={css.headingScaledSecond} style={{fontSize:'26px'}}><FormattedMessage id="SectionPartB.thirdCardText" /></h5>
            <div className={css.headingScaledSecondContent}>
           
            </div>
          </div>
          <div className={css.welcoming} onClick={(e)=>{onClickImage('Welcome')}}>
          <h5  className={css.welcomingScaled}><FormattedMessage id="SectionPartB.fourthCardText" /></h5>
            <div className={css.welcomingContent}>
            
            </div>
          </div>

        </div>

      </div>
    </>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionPartB.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  isInsideContainer: false,
  options: null,
};

SectionPartB.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionPartB;
