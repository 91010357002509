import React from 'react';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockBuilder from '../../BlockBuilder';

import SectionContainer from '../SectionContainer';

import css from './SectionPartC.module.css';
import Team1 from './img/wlh_team_1.jpg';
import Team2 from './img/wlh_team_2.jpg';
import Team3 from './img/wlh_team_3.jpg';
import Hiw_step1 from './img/hiw_step1.png';
import Hiw_step2 from './img/hiw_step2.png';
import Hiw_step3 from './img/hiw_step3.png';
import Hiw_step4 from './img/hiw_step4.png';



import { Button, NamedLink } from '../../../../components';
import { FormattedMessage } from 'react-intl';

// Section component that shows features.
// Block content are shown in a row-like way:
// [image] text
// text [image]
// [image] text
const SectionPartC = props => {

  return (
    <>
      <div className={css.esiansBlock}>
        <h4 className={css.landingSubTItle}>Esians</h4>
        <p className={css.description}><FormattedMessage id="SectionPartC.description" /></p>
        <p></p>
        <div className={css.esianItem}>
          <div className={css.esiListItems}>
            <img src={Team1} width={'100%'} className={css.itemImage} />
            <h3 className={css.teamHeading}><FormattedMessage id="SectionPartC.teamHeading1" /></h3>
            <p className={css.description}><FormattedMessage id="SectionPartC.teamDescription1" /></p>
          </div>
          <div className={css.esiListItems}>
            <img src={Team2} width={'100%'} />
            <h3 className={css.teamHeading}><FormattedMessage id="SectionPartC.teamHeading2" /></h3>
            <p className={css.description}><FormattedMessage id="SectionPartC.teamDescription2" /></p>
          </div>
          <div className={css.esiListItems}>
            <img src={Team3} width={'100%'} />
            <h3 className={css.teamHeading}><FormattedMessage id="SectionPartC.teamHeading3" /> </h3>
            <p className={css.description}><FormattedMessage id="SectionPartC.teamDescription3" /></p>
          </div>
        </div>
        {/* <Button className={css.buttonSearchAll}> */}
        <div className={css.searchUserPage}>
          <NamedLink
            name="SearchUserPage"
          // params={{ tab: 'signup' }}

          >
            <span>
              <FormattedMessage id="SectionPartC.searchUserPage" />
            </span>
          </NamedLink>
          </div>

        {/* </Button> */}
        <br />
      </div>

      <div className={css.howItWorks}>
        <h4 className={css.landingSubTItle}>
          <FormattedMessage id="SectionPartC.howItWorks"
            values={{
              b: msgFragment => <b>{msgFragment}</b>,
            }}
          />
          <FormattedMessage id="SectionPartC.howItWorksProcess"
            values={{
              b: msgFragment => <b>{msgFragment}</b>,
            }}

          />
        </h4>



        <div className={css.howItWorksItem}>
          <div className={css.itemBlockSection}>
            <div className={css.itemContentHiv}>
              <img src={Hiw_step1} width={200} />
              <div>
              <h5 className={css.headingHowitWorks}><FormattedMessage id="SectionPartC.headingHowitWorksA" values={{
                  b: msgFragment => <b>{msgFragment}</b>,
                }} /></h5>

                <ol>
                  <li>
                    <FormattedMessage id="SectionPartC.howitWorksDescriptionA1" />
                  </li>
                  <li>
                    <FormattedMessage id="SectionPartC.howitWorksDescriptionA2" />
                  </li>
                  <li>
                    <FormattedMessage id="SectionPartC.howitWorksDescriptionA3" />
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div className={css.itemBlockSection}>
            <div className={css.itemContentHiv}>
              <img src={Hiw_step2} width={200} />
              <div>
                <h5 className={css.headingHowitWorks}><FormattedMessage id="SectionPartC.headingHowitWorksB" values={{
                  b: msgFragment => <b>{msgFragment}</b>,
                }} /></h5>
                <ol>
                  <li>
                    <FormattedMessage id="SectionPartC.howitWorksDescriptionB1" />
                  </li>
                  <li>
                    <FormattedMessage id="SectionPartC.howitWorksDescriptionB2" />
                  </li>
                  <li>
                    <FormattedMessage id="SectionPartC.howitWorksDescriptionB3" />
                  </li>
                  {/* <li>
                    <FormattedMessage id="SectionPartC.howitWorksDescriptionB4" />
                  </li> */}
                </ol>
              </div>
            </div>
          </div>
        </div>


        <div className={css.howItWorksItem}>
          <div className={css.itemBlockSection}>
            
            <div className={css.itemContentHiv}>
              <img src={Hiw_step3} width={200} />
              <div>
              <h5 className={css.headingHowitWorksLastRow}><FormattedMessage id="SectionPartC.headingHowitWorksC" values={{
              b: msgFragment => <b>{msgFragment}</b>,
            }} /></h5>
                <ol>
                  <li>
                    <FormattedMessage id="SectionPartC.howitWorksDescriptionC1" />
                  </li>
                  <li>
                    <FormattedMessage id="SectionPartC.howitWorksDescriptionC2" />
                  </li>
                  <li>
                    <FormattedMessage id="SectionPartC.howitWorksDescriptionC3" />
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div className={css.itemBlockSection}>
            
            <div className={css.itemContentHiv}>
              <img src={Hiw_step4} width={200} />
              <div>
              <h5 className={css.headingHowitWorksLastRow}><FormattedMessage id="SectionPartC.headingHowitWorksD" values={{
              b: msgFragment => <b>{msgFragment}</b>,
            }} /></h5>
                <ol>
                  <li>
                    <FormattedMessage id="SectionPartC.howitWorksDescriptionD1" />
                  </li>
                  <li>
                    <FormattedMessage id="SectionPartC.howitWorksDescriptionD2" />
                  </li>
                  <li>
                    <FormattedMessage id="SectionPartC.howitWorksDescriptionD3" />
                  </li>
                  {/* <li>
                    <FormattedMessage id="SectionPartC.howitWorksDescriptionD4" />
                  </li> */}
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className={css.howItWorksVideo}>
          <iframe
            className={css.iframeBecomeaHost}
            src="https://www.youtube.com/embed/GH8Hy3GTBgM?autoplay=1&mute=1"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
      </div>



    </>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionPartC.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  isInsideContainer: false,
  options: null,
};

SectionPartC.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionPartC;
